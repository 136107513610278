import { Controller } from "@hotwired/stimulus"

import jQuery from 'jquery'
import '../global/select2'

import { apolloAutocompleteClient as apolloClient } from '../global/apollo'
import * as queries from '@queries/autocomplete'
import { tryJson } from '../global/utils/try_json'

export default class extends Controller {
  static values = {
    perPage: Number,
    select2Options: Object,
    query: String,
  }

  connect() {
    const perPage = this.perPage

    const dropdownParent = this.element.closest('form')

    const options = {
      language: 'de',
      lastPageInfo: null,
      dropdownParent,
      minimumInputLength: 1,
      ... this.select2Options,
    };

    const query = this.query

    const transport = (params, success, failure) => {
      const { data } = params

      const variables = {
        first: perPage,
        term: data.term === undefined ? '' : data.term,
      };

      if (data._type === 'query:append') {
        variables.after = options.lastPageInfo.endCursor
      }
      options.lastPageInfo = null

      apolloClient
        .query({ query, variables })
        .then(({ data }) => {
          const { nodes, pageInfo } = data.items

          const results = nodes.map((n) => { return { ...n } })

          const result = { results }

          if (pageInfo.hasNextPage === true) {
            options.lastPageInfo = pageInfo;
            result.pagination = { more: true };
          }

          success(result);
        })
        .catch((error) => {
          console.error(error);
          return failure(error);
        });
    };

    options['ajax'] = { transport, delay: 500 }

    const placeholder = this.element.querySelector("option[value='']");

    if (placeholder) {
      if (!Object.prototype.hasOwnProperty.call(options, 'allowClear')) {
        options.allowClear = true;
      }
      if (!Object.prototype.hasOwnProperty.call(options, 'placeholder')) {
        options.placeholder = placeholder.innerText;
      }
    }

    this.$select2 = jQuery(this.element).select2(options);
  }

  disconnect() {
    this.$select2.select2('destroy')
  }

  get perPage() {
    if (this.hasPerPageValue) {
      return this.perPageValue
    }

    const i = Number.parseInt(this.data.get('per-page'), 10)

    if (!Number.isNaN(i)) {
      return i
    }

    return 10
  }

  get select2Options() {
    if (this.hasSelect2OptionsValue) {
      return this.select2OptionsValue
    }

    tryJson(this.data.get('options'))
  }

  get query() {
    if (this.hasQueryValue) {
      return queries[this.queryValue]
    }

    return queries[this.data.get('query')]
  }
}
